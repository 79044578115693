import $ from "jquery";

export function initImages() {
    const lazyImages = document.getElementsByClassName('lazy-bg');
    // options for the observer
    const backgroundOptions = {
        threshold: 0,
        rootMargin: "0px 0px 50px 0px"
    };
    // the observer
    const imageObserver = new IntersectionObserver((entries, imageObserver) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                showImageBackground(entry.target);
                imageObserver.unobserve(entry.target);
            }
        });
    }, backgroundOptions);
    // observe each image
    [...lazyImages].forEach(image => {
        imageObserver.observe(image);
    });
    // show background image
    function showImageBackground(node) {
        node.classList.remove('lazy-bg');
    }

    const viewportWidth = $(window).innerWidth();
    const heroImage = $('#hero-image-base');

    $('img').each(function () {
        const element = $(this);
        if (!element.attr('src')) {
            element.attr('src', element.data('src'));
            element.attr('srcset', element.data('srcset'));
        }
    });

    if (viewportWidth >= 415 && heroImage.length) {
        heroImage.attr('src', heroImage.data('src'));
    }
}